import { nodeInstance } from "@/plugins/axios";

const announcementService = {};

announcementService.listAnnouncement = (params) => {
    return nodeInstance.get(`/api/announcement/?skip=${params.skip}&take=${params.take}&state=${params.state}`);
};

announcementService.createAnnouncement = obj => {
    return nodeInstance.post(`/api/announcement`, obj);
};

announcementService.updateAnnouncement = (id, obj) => {
    return nodeInstance.patch(`/api/announcement/${id}`, obj);
};

announcementService.deleteAnnouncement = id => {
    return nodeInstance.delete(`/api/announcement/${id}`);
};

announcementService.announcementByState = (state) => {
    return nodeInstance.get(`/api/announcement/state/${state}`);
};

announcementService.announcementByStateAndInstitution = obj => {
    return nodeInstance.post(`/api/announcement/institution`, obj);
};

announcementService.myAnnouncementByState = (state) => {
    return nodeInstance.get(`/api/announcement/user/list/${state}`);
};

announcementService.commentAndRate = obj => {
    return nodeInstance.post(`/api/announcementQualification`, obj);
};

announcementService.getSpecificAnnouncement = (id) => {
    return nodeInstance.get(`/api/announcement/${id}`);
}

announcementService.getSearchAnnouncement = (params) => {
    return nodeInstance.post(`/api/announcement/find?skip=${params.skip}&take=${params.take}`, { word: params.word, publicState: params.publicState, institutionId: params.institutionId });
}

announcementService.getParticipatingGroups = (announcementId) => {
    return nodeInstance.get(`/api/announcement/groupAnnouncement?announcementId=${announcementId}`);
};

announcementService.getMyParticipatingGroups = (params) => {
    return nodeInstance.get(`/api/announcement/groupAnnouncement/user?announcementId=${params.announcementId}`);
};

announcementService.announcementExternalByState = (state) => {
    return nodeInstance.get(`/api/announcement/external?state=${state}`);
};

announcementService.getSpecificAnnouncementExternal = (id) => {
    return nodeInstance.get(`/api/announcement/external/${id}`);
}

announcementService.groupAnnouncementByState = (params) => {
    return nodeInstance.get(`/api/announcement/groupAnnouncement/byState?announcementId=${params.announcementId}&stateGroupAnnouncement=${params.stateGroupAnnouncement}`);
};

announcementService.getSearchAnnouncementExternal = (params) => {
    return nodeInstance.post(`/api/announcement/external/find?skip=${params.skip}&take=${params.take}`, { word: params.word });
}

export default announcementService;