<template src="./ViewAllMyAnnouncement.html"></template>

<script>
import announcementService from "../../services/Announcement";

import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "ViewAllMyAnnouncement",
  components: {
    ArrobaMedellinArrobitaNoData,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Mis convocatorias",
    },
    notFound: {
      type: String,
      default: "No tienes convocatorias abiertas en este momento",
    },
  },
  data() {
    return {
      buttonText: "Ver información",
      errorMessage: false,
      myCalls: [],
      role: "",
      status: null,
    };
  },
  created() {
    this.getMyCalls();
    this.role = this.$store.state.User.user.role.name;
  },
  methods: {
    async getMyCalls() {
      try {
        this.myCalls = await announcementService.myAnnouncementByState(1);
        this.status = true;
      } catch (error) {
        console.error(error);
      }
    },
    announcementOpen() {
      this.myCalls = [];
      this.getMyCalls();
    },
    async announcementClosed() {
      try {
        this.myCalls = [];
        this.myCalls = await announcementService.myAnnouncementByState(0);
        this.status = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationTeacherInvestigator() {
      return ["TeacherInvestigator"].some((a) => a === this.role);
    },
  },
};
</script>

<style scoped lang="scss" src="./ViewAllMyAnnouncement.scss"></style>